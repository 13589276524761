import React, { Component } from "react";
import { Container, Row, Card } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";

class Services extends Component {
  
  render() {
    return (
    
      <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
      <Container className="price-list mt-10" fluid>
    
        <h1 className="title">Threading</h1>
        <ul className="list-unstyled price-list-menu">
          <li>
            Eyebrow <span className="pull-right">$17</span>
          </li>
          <li>
            Lip <span className="pull-right">$10</span>
          </li>
          <li>
            Chin <span className="pull-right">$10</span>
          </li>
          <li>
            Neck <span className="pull-right">$10</span>
          </li>
          <li>
            Forehead <span className="pull-right">$10</span>
          </li>
          <li>
            Sides Burn <span className="pull-right">$15</span>
          </li>
          <li>
            Full Face <span className="pull-right">$47</span>
          </li>
          </ul>
          <h1 className="title">Waxing</h1>
          <ul className="list-unstyled price-list-menu">
          <li>
            Full Arm Waxing <span className="pull-right">$47</span>
          </li>
          <li>
            Half Arm Waxing <span className="pull-right">$30</span>
          </li>
          <li>
            Full Leg Waxing <span className="pull-right">$70</span>
          </li>
          <li>
            Half Leg Waxing <span className="pull-right">$50</span>
          </li>
          <li>
            Under Arm Waxing <span className="pull-right">$25</span>
          </li>
        </ul>
        <hr></hr>
        <h1 className="title">Lashes/Tinting</h1>
        <ul className="list-unstyled price-list-menu">
          <li>
            Lash Lift <span className="pull-right">$85</span>
          </li>
          <li>
            Lash Lift/Tint <span className="pull-right">$150</span>
          </li>
          <li>
            Eyebrow Tint <span className="pull-right">$25</span>
          </li>
          <li>
            Lash Tint <span className="pull-right">$35</span>
          </li>
          <li>
            Brow Lamination <span className="pull-right">$75</span>
          </li>
          </ul>
      </Container>
    </ScrollAnimation>
    );
  }
}
export default Services;
